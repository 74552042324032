import { graphql, useStaticQuery } from "gatsby"

export const useTestDriveTiles = () => {
  const data = useStaticQuery(graphql`
    query {
      allContentfulTestDrivePage(
        filter: { contentful_id: { ne: "3lVyr1jS9AIDo9oqjL7q08" } }
      ) {
        edges {
          node {
            id
            contentful_id
            slug
            useCaseName
            thumbnailImage {
              ...gatsbyImageTracedSVG
            }
          }
        }
      }
    }
  `)

  return data.allContentfulTestDrivePage.edges.map(e => e.node)
}
